import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/pageBanner"
import PageSection from "../components/pageSection"
import ComponentFactory from "../ComponentFactory"

const Page = ({ data }) => {
  const { banner, sections, slug } = data.CMS.page
  const pageTags = data.CMS.page.metadata.tags

  const sectionEls = sections.map((section, index) => {
    const { components, customClass, id } = section
    return (
      <PageSection
        id={`section_${index + 1}`}
        className={customClass}
        key={`section_${id}`}
      >
        {components.map(data => ComponentFactory.create(data))}
      </PageSection>
    )
  })

  return (
    <Layout pageSlug={slug || null}>
      <SEO pageTags={pageTags} />
      <PageBanner {...banner} />
      {sectionEls}
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    CMS {
      page(id: $id) {
        id
        slug
        banner {
          ...PageBanner
        }
        metadata {
          ...Metadata
        }
        sections {
          id
          customClass: classname
          components {
            ...Component
          }
        }
      }
    }
  }
`

export default Page
